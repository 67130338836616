import { Injectable } from "@angular/core";
import { ProductsService } from "@app/core/services/products.service";
import { ToastService } from "@app/core/services/toast.service";
import { ProductDetailsService } from "@app/product-details/services/product-details.service";
import { DiaryService } from "@app/profile/services";
import * as fromCategoryFilter from "@app/shared/stores/category-filter/store";
import * as fromNutrientFilter from "@app/shared/stores/nutrient-filter/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromProduct from "../../store";
import * as ProductActions from "../actions/product.actions";
import * as DiarySettingSelects from "../selectors/diary-settings.selectors";
import * as ProductSelects from "../selectors/product.selectors";

@Injectable()
export class ProductEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProductActions.loadProducts,
        ProductActions.loadProductsNextPage,
        ProductActions.setProductFilter,
        fromCategoryFilter.applyCategoryFilterListRation,
        fromNutrientFilter.applyNutrientFiltersRation,
      ),
      concatLatestFrom((action) => [
        this.store.pipe(select(DiarySettingSelects.selectRationId())),
        this.store.pipe(select(ProductSelects.selectProductFilter())),
        this.store.pipe(
          select(fromNutrientFilter.selectAppliedNutrientFilterIds()),
        ),
        this.store.pipe(
          select(fromCategoryFilter.selectAppliedCategoryFilterIdList()),
        ),
      ]),
      switchMap(([action, rationId, productFilter, filters, categories]) => {
        productFilter = {
          ...productFilter,
          filters,
          categories,
        };
        return this.diaryService.loadProducts(rationId, productFilter).pipe(
          map((response) => {
            return ProductActions.loadProductsSuccess({
              products: response.data,
              isAppend:
                action.type === ProductActions.loadProductsNextPage.type,
            });
          }),
          catchError((error) => {
            this.toastService.error(error);
            return of(ProductActions.loadProductsFailure({ error }));
          }),
        );
      }),
    ),
  );

  loadProductRecommendations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProductRecommendations),
      concatLatestFrom((action) => [
        this.store.pipe(select(DiarySettingSelects.selectRationId())),
        this.store.pipe(select(DiarySettingSelects.selectActiveEatingFoodId)),
      ]),
      switchMap(([action, rationId, eatingFoodId]) => {
        return this.diaryService
          .loadFoodRecommendations(rationId, eatingFoodId)
          .pipe(
            map((response) => {
              return ProductActions.loadProductRecommendationsSuccess({
                products: response.data,
              });
            }),
            catchError((error) => {
              this.toastService.error(error);
              return of(
                ProductActions.loadProductRecommendationsFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  // addProductsDetailsToStorage$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ProductActions.addProductsDetailsToStorage),
  //       tap(({ products }) =>
  //         this.productDetailsService.addProductsDetailsToStorage(products)
  //       )
  //     ),
  //   { dispatch: false }
  // );

  // toggleProductToLocked$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProductActions.toggleProductToLocked),
  //     switchMap(({ product }: any) => {
  //       return this.productsService
  //         .toggleProductToLocked(product.product_id)
  //         .pipe(
  //           map((response) => {
  //             this.toastService.success(response.message);
  //             return ProductActions.toggleProductToLockedSuccess({
  //               product: response.data,
  //             });
  //           }),
  //           catchError((error) => {
  //             this.toastService.error(error);
  //             return of(ProductActions.toggleProductToLockedFailure({ error }));
  //           })
  //         );
  //     })
  //   )
  // );

  constructor(
    private actions$: Actions,
    private store: Store<fromProduct.State>,
    private diaryService: DiaryService,
    private productDetailsService: ProductDetailsService,
    private productsService: ProductsService,
    private toastService: ToastService,
  ) {}
}

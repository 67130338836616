import { FilterParams } from "@app/shared/models/filter-params.model";
import * as fromCategoryFilter from "@app/shared/stores/category-filter/store";
import * as fromNutrientFilter from "@app/shared/stores/nutrient-filter/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as DietarySupplementActions from "../actions/dietary-supplement.actions";

export const productsFeatureKey = "dietary-supplement";

export interface State extends EntityState<any> {
  selectedRecommendedProductId: number | null;
  isLoading: boolean;
  isLoaded: boolean;
  isFirstLoad: boolean;
  meta: {
    total: number;
    current_page: number;
  };
  productFilter: FilterParams;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (product: any) => product.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedRecommendedProductId: null,
  isLoading: false,
  isLoaded: false,
  isFirstLoad: false,
  meta: {
    total: 0,
    current_page: 1,
  },
  productFilter: {
    page: 1,
    search: "",
    filters: [],
    order: "desc",
    dietRestriction: 0,
    categories: [],
  },
});

export const reducer = createReducer(
  initialState,

  on(DietarySupplementActions.loadProfileDSProducts, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DietarySupplementActions.loadProfileDSProductsSuccess,
    (state, { products, meta, isAppend }) => ({
      ...adapter.addMany(
        products,
        isAppend
          ? state
          : adapter.removeMany((product) => !product.locked, state),
      ),
      meta,
      isLoading: false,
      isFirstLoad: false,
    }),
  ),
  on(
    DietarySupplementActions.loadProfileDSProductsFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),

  on(DietarySupplementActions.loadProfileDSProductsNextPage, (state: any) => ({
    ...state,
    isLoading: true,
    productFilter: {
      ...state.productFilter,
      page: state.productFilter.page + 1,
    },
  })),

  on(DietarySupplementActions.firstLoadProfileDSProducts, (state) => ({
    ...state,
    productFilter: {
      ...state.productFilter,
      page: 1,
    },
    isFirstLoad: true,
  })),

  on(
    DietarySupplementActions.setProfileDSProductFilter,
    (state, { productFilter }) => ({
      ...state,
      isLoading: true,
      productFilter: {
        ...state.productFilter,
        ...productFilter,
        page: 1,
      },
    }),
  ),

  on(
    fromCategoryFilter.applyCategoryFilterList,
    fromNutrientFilter.applyNutrientFilters,
    (state) => ({
      ...state,
      isLoading: true,
      productFilter: {
        ...state.productFilter,
        page: 1,
      },
    }),
  ),

  on(DietarySupplementActions.loadProfileDSProductRecommendations, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DietarySupplementActions.loadProfileDSProductRecommendationsSuccess,
    (state, { products }) => ({
      ...adapter.setAll(products, state),
      isLoading: false,
      isFirstLoad: false,
    }),
  ),
  on(
    DietarySupplementActions.loadProfileDSProductRecommendationsFailure,
    (state, { error }) => ({
      ...state,
      error,
      isLoading: false,
    }),
  ),

  on(
    DietarySupplementActions.setActiveRecommendedProfileDSProduct,
    (state, { selectedRecommendedProductId }) => ({
      ...state,
      selectedRecommendedProductId,
    }),
  ),

  on(DietarySupplementActions.clearProfileDSProducts, (state) => ({
    ...adapter.removeAll({ ...state, ...initialState }),
  })),

  on(DietarySupplementActions.toggleProfileDSProductToLocked, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DietarySupplementActions.toggleProfileDSProductToLockeProfileDSuccess,
    (state, { product }: any) => ({
      ...adapter.updateOne({ id: product.id, changes: product }, state),
      isLoading: false,
    }),
  ),
  on(
    DietarySupplementActions.toggleProfileDSProductToLockedFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectProductIds = selectIds;
export const selectProductEntities = selectEntities;
export const selectAllProducts = selectAll;
export const selectProductTotal = selectTotal;

export const getSelectedRecommendedProductId = (state: State) =>
  state.selectedRecommendedProductId;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectIsLoaded = (state: State) => state.isLoaded;
export const selectIsFirstLoad = (state: State) => state.isFirstLoad;
export const selectProductFilter = (state: State) => state.productFilter;

export const selectMetaOfDSProducts = (state: State) => state.meta;

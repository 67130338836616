import { createSelector } from "@ngrx/store";
import { selectRecipeState } from "../reducers";
import * as fromRecipe from "../reducers/recipe.reducer";
import * as DiarySettingsSelectors from "../selectors/diary-settings.selectors";

export const selectIsRecipesLoading = () =>
  createSelector(selectRecipeState(), fromRecipe.selectIsLoading);

// export const selectRecipesIsLoaded = () => createSelector(
//   selectRecipeState(),
//   fromRecipe.selectIsLoaded,
// );

export const selectIsRecipesFirstLoad = () =>
  createSelector(selectRecipeState(), fromRecipe.selectIsFirstLoad);

export const selectRecipeIds = () =>
  createSelector(selectRecipeState(), fromRecipe.selectIds);

export const selectRecipeEntities = () =>
  createSelector(selectRecipeState(), fromRecipe.selectEntities);

export const selectAllRecipes = () =>
  createSelector(selectRecipeState(), fromRecipe.selectAll);

export const selectRecipeTotal = () =>
  createSelector(selectRecipeState(), fromRecipe.selectTotal);

export const selectRecipeFilter = () =>
  createSelector(selectRecipeState(), fromRecipe.selectRecipeFilter);

export const selectAllRecipesAddedToEating = () =>
  createSelector(
    selectAllRecipes(),
    DiarySettingsSelectors.selectCurrentEating(),
    (allRecipes: any, currentEating) => {
      if (allRecipes.length && currentEating) {
        return allRecipes.map((recipe: any) => ({
          ...recipe,
          isAddedToEating: currentEating.food.some(
            (item: any) => item.product_id === recipe.product_id,
          ),
          id:
            currentEating.food.find(
              (item: any) => item.product_id === recipe.product_id,
            )?.id || null,
        }));
      }
    },
  );

export const selectCurrentRecommendedRecipeId = () =>
  createSelector(
    selectRecipeState(),
    fromRecipe.getSelectedRecommendedRecipeId,
  );

export const selectCurrentRecommendedRecipe = () =>
  createSelector(
    selectCurrentRecommendedRecipeId(),
    selectRecipeEntities(),
    (currentRecommendedRecipeId: any, recipeEntities: any) => {
      return recipeEntities[currentRecommendedRecipeId];
    },
  );

export const selectAllRecipesRecommendation = () =>
  createSelector(
    selectAllRecipes(),
    selectCurrentRecommendedRecipeId(),
    (allRecipes, сurrentRecommendedRecipeId) => {
      return allRecipes.map((recipe) => ({
        ...recipe,
        isActive: recipe.product_id === сurrentRecommendedRecipeId,
        isRecommended: true,
      }));
    },
  );

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class DiaryDetailsService {
  constructor(private http: HttpClient) {}

  loadFoodRecommendations(
    rationId: number,
    eatingFoodId: any,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/recommendation/${eatingFoodId}`,
    );
  }

  loadRecipes(rationId: number, recipeFilter: any, take = 50): Observable<any> {
    const { page, ...filter } = recipeFilter;

    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/recipe`,
      {
        params: {
          page,
          per_limit: take,
          filter: JSON.stringify(filter),
        },
      },
    );
  }
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { translate } from "@ngneat/transloco";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { BrowserStorageService } from "@app/core/services/browser-storage.service";
import { PatientsService } from "@app/core/services/patients.service";
import { ProfileService } from "@app/core/services/profile.service";
import { ToastService } from "@app/core/services/toast.service";
import { DiaryService, QuestionnaireService } from "@app/profile/services";
import * as fromProfile from "@app/profile/store";
import * as DiarySettingsActions from "@app/profile/store/actions/diary-settings.actions";
import * as PatientActions from "@app/profile/store/actions/patient.actions";
import * as PatientSelectors from "@app/profile/store/selectors/patient.selectors";

@Injectable()
export class PatientEffects {
  checkPatientHash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientHash),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectPatientHash())),
      ),
      switchMap(([action, hash]) => {
        return this.patientsService.checkPatientHash(hash).pipe(
          switchMap((response) => {
            this.toastService.success(translate("toast.hello"));
            return [
              PatientActions.checkPatientHashSuccess({
                patientId: response.user_id,
              }),
              PatientActions.addProfileInfoToStorage({
                profileInfo: {
                  hash,
                  id: response.user_id,
                },
              }),
            ];
          }),
          catchError((error) => {
            this.toastService.error(error.error.message);
            return of(PatientActions.checkPatientHashFailure({ error }));
          }),
        );
      }),
    ),
  );

  checkPatientSecretWord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientSecretWord),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectPatientId())),
        this.store.pipe(select(PatientSelectors.selectProfileInfo())),
      ),
      switchMap(([{ secretWord }, patientId, profileInfo]) => {
        console.log(patientId, profileInfo);
        return this.patientsService
          .checkPatientSecretWord(secretWord, patientId || profileInfo.id)
          .pipe(
            switchMap((response) => {
              return [
                PatientActions.checkPatientSecretWordSuccess({
                  patient: response,
                }),
                PatientActions.addProfileInfoToStorage({
                  profileInfo: {
                    ...profileInfo,
                  },
                }),
              ];
            }),
            tap(() => this.router.navigate(["/profile/questions/main"])),
            catchError((error) => {
              this.toastService.error(translate("toast.secret-word-wrong"));
              return of(
                PatientActions.checkPatientSecretWordFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  checkPatientSecretWordAndLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientSecretWordAndLogin),
      switchMap(({ secretWord, hash }) => {
        return this.questionnaireService
          .loginPatientWithSecretWordAndHash(secretWord, hash)
          .pipe(
            switchMap(
              (response: {
                access_token: string;
                ration_id: number;
                week_id: number;
              }) => {
                const { access_token, ration_id, week_id } = response;
                return [
                  PatientActions.checkPatientSecretWordAndLoginSuccess({
                    access_token,
                  }),
                  DiarySettingsActions.updateActiveWeakId({ weakId: week_id }),
                  DiarySettingsActions.updateActiveRationId({
                    ration_id: ration_id,
                  }),
                ];
              },
            ),
            catchError((error) => {
              this.toastService.error(translate("toast.secret-word-wrong"));
              return of(
                PatientActions.checkPatientSecretWordAndLoginFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  checkPatientSecretWordAndLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientSecretWordAndLoginSuccess),
      switchMap(() => [PatientActions.loadQuestionnairePatient()]),
    ),
  );

  loadQuestionnairePatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.loadQuestionnairePatient),
      switchMap(() => {
        return this.questionnaireService.loadPatient().pipe(
          switchMap((response: { data: any }) => {
            const { data } = response;
            return [
              PatientActions.loadQuestionnairePatientSuccess({
                patient: data,
              }),
            ];
          }),
          tap(() => this.router.navigate(["/profile/questions/main"])),
          catchError((error) => {
            this.toastService.error(translate("toast.patient-auth-errors"));
            return of(
              PatientActions.loadQuestionnairePatientFailure({ error }),
            );
          }),
        );
      }),
    ),
  );

  loadCurrentPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.loadCurrentPatient),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectProfileInfo())),
      ),
      switchMap(([action, profileInfo]) => {
        return this.patientsService
          .checkPatientSecretWord(profileInfo.secretWord, profileInfo.id)
          .pipe(
            map((response) => {
              // this.toastService.success('Данные обновлены');
              return PatientActions.loadCurrentPatientSuccess({
                patient: response,
              });
            }),
            catchError((error) => {
              this.toastService.error(translate("toast.data-not-updated"));
              return of(PatientActions.loadCurrentPatientFailure({ error }));
            }),
          );
      }),
    ),
  );

  getProfileInfoFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.getProfileInfoFromStorage),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectPatientHash())),
      ),
      switchMap(([action, hash]) => {
        return this.profileService.getProfileInfoFromStorage().pipe(
          switchMap((profileInfo) => {
            return [
              PatientActions.getProfileInfoFromStorageSuccess({ profileInfo }),
              ...(hash ? [PatientActions.checkPatientHash()] : []),
              ...(profileInfo?.secretWord
                ? [PatientActions.loadCurrentPatient()]
                : []),
            ];
          }),
          catchError((error) =>
            of(PatientActions.getProfileInfoFromStorageFailure({ error })),
          ),
        );
      }),
    ),
  );

  addProfileInfoToStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.addProfileInfoToStorage),
      mergeMap(({ profileInfo }) =>
        this.profileService.addProfileInfoToStorage(profileInfo).pipe(
          map(() =>
            PatientActions.addProfileInfoToStorageSuccess({ profileInfo }),
          ),
          catchError((error) =>
            of(PatientActions.addProfileInfoToStorageFailure({ error })),
          ),
        ),
      ),
    ),
  );

  updateCurrentPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.updateCurrentPatient),
      switchMap(({ patient }) => {
        return this.patientsService.updatePatient(patient).pipe(
          map((response) => {
            // this.toastService.success('Данные обновлены');
            return PatientActions.updateCurrentPatientSuccess({
              patient: response.data.patient,
            });
          }),
          catchError((error) => {
            this.toastService.error(translate("toast.data-not-updated"));
            return of(PatientActions.updateCurrentPatientFailure({ error }));
          }),
        );
      }),
    ),
  );

  updatePatientQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.updatePatientQuestionnaire),
      withLatestFrom(this.store.pipe(select(fromProfile.selectPatientState()))),
      switchMap(([{ patient }, patientInfo]) => {
        return this.questionnaireService.updateQuestionnaire(patient).pipe(
          map((response) => {
            this.toastService.success("Данные обновлены");
            return PatientActions.updatePatientQuestionnaireSuccess({
              patient: response.data.patient,
            });
          }),
          catchError((error) => {
            this.toastService.error(translate("toast.data-not-updated"));
            return of(
              PatientActions.updatePatientQuestionnaireFailure({ error }),
            );
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromProfile.State>,
    private patientsService: PatientsService,
    private questionnaireService: QuestionnaireService,
    private diaryService: DiaryService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private browserStorageService: BrowserStorageService,
    private router: Router,
  ) {}
}

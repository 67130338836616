import { EatingFood } from "@app/shared/models/ration-details/eating-food.model";
import { Recipe } from "@app/shared/models/recipe.model";
import { createAction, props } from "@ngrx/store";

export const loadRecipes = createAction(
  "[Diary Ration Recipe / API] Load Recipes",
);
export const loadRecipesSuccess = createAction(
  "[Diary Ration Recipe / API] Load Recipes Success",
  props<{ recipes: Recipe[]; isAppend: boolean }>(),
);
export const loadRecipesFailure = createAction(
  "[Diary Ration Recipe / API] Load Recipes Failure",
  props<{ error: any }>(),
);

export const firstLoadRecipes = createAction(
  "[Diary Ration Recipe] First Load Recipes",
);

export const setRecipeFilter = createAction(
  "[Diary Ration Recipe / API] Set Recipe Filter",
  props<{ recipeFilter: any }>(),
);

export const loadRecipesNextPage = createAction(
  "[Diary Ration Recipe / API] Load Recipes Next Page",
);

export const loadRecipeRecommendations = createAction(
  "[Diary Ration Recipe / API] Load Recipe Recommendations",
);
export const loadRecipeRecommendationsSuccess = createAction(
  "[Diary Ration Recipe / API] Load Recipe Recommendations Success",
  props<{ recipes: EatingFood[] }>(),
);
export const loadRecipeRecommendationsFailure = createAction(
  "[Diary Ration Recipe / API] Load Recipe Recommendations Failure",
  props<{ error: any }>(),
);

export const setActiveRecommendedRecipe = createAction(
  "[Diary Ration Recipe] Set Active Recommended Recipe",
  props<{ selectedRecommendedRecipeId: number }>(),
);

export const clearRecipes = createAction("[Diary Ration Recipe] Clear Recipes");

export const resetFilters = createAction("[Diary Ration Recipe] Reset Fiters");

export const resetRecipeState = createAction(
  "[Diary Ration Recipe] Reset Recipe State",
);

export const toggleRecipeToFavorite = createAction(
  "[Diary Ration Recipe / API] Toggle Recipe To Favorite",
  props<{ recipe: EatingFood }>(),
);
export const toggleRecipeToFavoriteSuccess = createAction(
  "[Diary Ration Recipe / API] Toggle Recipe To Favorite Success",
  props<{ recipe: EatingFood }>(),
);
export const toggleRecipeToFavoriteFailure = createAction(
  "[Diary Ration Recipe / API] Toggle Recipe To Favorite Failure",
  props<{ error: any }>(),
);

export const toggleRecipeToLocked = createAction(
  "[Diary Recipe Recipe / API] Toggle Recipe To Locked",
  props<{ recipe: EatingFood }>(),
);
export const toggleRecipeToLockedSuccess = createAction(
  "[Diary Recipe Recipe / API] Toggle Recipe To Locked Success",
  props<{ recipe: { id: number; locked: boolean } }>(),
);
export const toggleRecipeToLockedFailure = createAction(
  "[Diary Recipe Recipe / API] Toggle Recipe To Locked Failure",
  props<{ error: any }>(),
);

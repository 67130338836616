import { GreetingOnly } from "@app/profile/pages/questions-greeting/questions-greeting.component";
import * as questionsSettingsActions from "@app/profile/store/actions/questions-settings.actions";
import { Action, createReducer, on } from "@ngrx/store";

export const questionsSettingsFeatureKey = "questionsSettings";

export interface State {
  only: GreetingOnly;
  hash_id: string | null;
}

export const initialState: State = {
  only: null,
  hash_id: null,
};

export const questionsSettingsReducer = createReducer(
  initialState,

  on(questionsSettingsActions.updateOnlyParameter, (state, { only }) => ({
    ...state,
    only,
  })),
  on(questionsSettingsActions.updateHashParameter, (state, { hash_id }) => ({
    ...state,
    hash_id,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return questionsSettingsReducer(state, action);
}

export const selectSettingState = (state: State) => state;
export const selectOnlyParameter = (state: State) => state.only;
export const selectHashParameter = (state: State) => state.hash_id;

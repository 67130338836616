import { Injectable } from "@angular/core";
import { ToastService } from "@app/core/services/toast.service";
import { DiaryRecipesService } from "@app/profile/services";
import { DiaryDetailsService } from "@app/profile/services/diary-details.service";
import * as fromRecipes from "@app/ration-details/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as RecipeActions from "../actions/recipe.actions";
import * as DiarySettingsSelectors from "../selectors/diary-settings.selectors";
import * as RecipeSelectors from "../selectors/recipe.selectors";

@Injectable()
export class RecipeEffects {
  loadRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RecipeActions.loadRecipes,
        RecipeActions.loadRecipesNextPage,
        RecipeActions.setRecipeFilter,
        // fromCategoryFilter.applyCategoryFilterList,
        // fromNutrientFilter.applyNutrientFilters,
      ),
      concatLatestFrom((action) => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(RecipeSelectors.selectRecipeFilter())),
        //this.store.pipe(select(fromNutrientFilter.selectAppliedNutrientFilterIds())),
        //this.store.pipe(select(fromCategoryFilter.selectAppliedCategoryFilterIdList())),
      ]),
      switchMap(([action, rationId, recipeFilter]) => {
        // recipeFilter = {
        //   ...recipeFilter,
        //   filters,
        //   categories,
        // }
        return this.diaryDetailsService
          .loadRecipes(rationId, recipeFilter)
          .pipe(
            map((response) => {
              return RecipeActions.loadRecipesSuccess({
                recipes: response.data,
                isAppend:
                  action.type === RecipeActions.loadRecipesNextPage.type,
              });
            }),
            catchError((error) => {
              this.toastService.error(error);
              return of(RecipeActions.loadRecipesFailure({ error }));
            }),
          );
      }),
    ),
  );

  loadRecipeRecommendations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipeActions.loadRecipeRecommendations),
      concatLatestFrom((action) => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveEatingId)),
      ]),
      switchMap(([action, rationId, eatingFoodId]) => {
        return this.diaryDetailsService
          .loadFoodRecommendations(rationId, eatingFoodId)
          .pipe(
            map((response) => {
              return RecipeActions.loadRecipeRecommendationsSuccess({
                recipes: response.data,
              });
            }),
            catchError((error) => {
              this.toastService.error(error);
              return of(
                RecipeActions.loadRecipeRecommendationsFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  toggleRecipeToFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipeActions.toggleRecipeToFavorite),
      switchMap(({ recipe }) => {
        return this.recipesService
          .toggleRecipeToFavorite(recipe.product_id)
          .pipe(
            map((response) => {
              this.toastService.success(response.message);
              return RecipeActions.toggleRecipeToFavoriteSuccess({
                recipe: response.data,
              });
            }),
            catchError((error) => {
              this.toastService.error(error);
              return of(RecipeActions.toggleRecipeToFavoriteFailure({ error }));
            }),
          );
      }),
    ),
  );

  toggleRationDetailsRecipeToLocked$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipeActions.toggleRecipeToLocked),
      switchMap(({ recipe }: any) => {
        return this.recipesService.toggleRationToLocked(recipe.product_id).pipe(
          map((response) => {
            this.toastService.success(response.message);
            return RecipeActions.toggleRecipeToLockedSuccess({
              recipe: response.data,
            });
          }),
          catchError((error) => {
            this.toastService.error(error);
            return of(RecipeActions.toggleRecipeToLockedFailure({ error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRecipes.State>,
    private diaryDetailsService: DiaryDetailsService,
    private recipesService: DiaryRecipesService,
    private toastService: ToastService,
  ) {}
}

import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as fromQuestions from "@app/profile/store";
import * as PatientActions from "@app/profile/store/actions/patient.actions";
import * as QuestionsActions from "@app/profile/store/actions/questions.actions";
import * as PatientSelectors from "@app/profile/store/selectors/patient.selectors";
import * as fromQuestionsSettings from "@app/profile/store/selectors/questions-settings.selectors";
import * as QuestionsSelectors from "@app/profile/store/selectors/questions.selectors";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { filter, tap, withLatestFrom } from "rxjs/operators";

@Injectable()
export class QuestionsEffects {
  navigateCurrentQuestion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          QuestionsActions.navigatePreviousQuestion,
          QuestionsActions.navigateCurrentQuestion,
          QuestionsActions.navigateNextQuestion,
          QuestionsActions.setCurrentQuestionStep,
        ),
        withLatestFrom(
          this.store.pipe(select(QuestionsSelectors.selectCurrentQuestionId())),
          this.store.pipe(select(QuestionsSelectors.selectQuestionEntities())),
          this.store.pipe(select(fromQuestionsSettings.selectAllSettings())),
        ),
        filter(([action, questionId]) => !!questionId),
        tap(([action, questionId, questionEntities, settings]) => {
          // const { only } = settings;
          this.router.navigate([
            "/profile/questions/main",
            questionEntities[questionId].path,
          ]);
        }),
      ),
    { dispatch: false },
  );

  // TODO Сделать рефактор, когда будет полностью анкета висеть на беке
  navigatePatient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PatientActions.updateCurrentPatientSuccess,
          PatientActions.updatePatientQuestionnaireSuccess,
          QuestionsActions.setQuestionSteps,
        ),
        withLatestFrom(
          this.store.pipe(select(PatientSelectors.selectCompletedStepsCount())),
          this.store.pipe(
            select(PatientSelectors.selectActivePatientPageData()),
          ),
        ),
        filter(([action, completedSteps, page]) => page === "questions"),
        tap(([action, completedSteps]) => {
          console.log(completedSteps);
          this.store.dispatch(
            QuestionsActions.setCurrentQuestionStep({
              selectedQuestionId: completedSteps,
            }),
          );
          this.store.dispatch(QuestionsActions.setQuestionStepsComplete());
          this.store.dispatch(QuestionsActions.checkCompletedSteps());
        }),
      ),
    { dispatch: false },
  );

  checkCompletedSteps$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuestionsActions.checkCompletedSteps),
        withLatestFrom(
          this.store.pipe(select(PatientSelectors.selectCompletedStepsCount())),
          this.store.pipe(select(QuestionsSelectors.selectQuestionTotal())),
        ),
        tap(([action, completedSteps, questionTotal]) =>
          completedSteps === questionTotal
            ? this.store.dispatch(QuestionsActions.openCompleteDialog())
            : this.store.dispatch(QuestionsActions.navigateNextQuestion()),
        ),
      ),
    { dispatch: false },
  );

  openCompleteDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuestionsActions.openCompleteDialog),
        tap(() => {
          this.router.navigate([
            "/profile/questions/main/stress",
            { outlets: { modal: ["complete"] } },
          ]);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromQuestions.State>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
}

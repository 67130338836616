import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class DiaryRecipesService {
  constructor(private http: HttpClient) {}

  loadRecipes(recipeFilter: any, take = 50): Observable<any> {
    const { page, ...filter } = recipeFilter;

    return this.http.get<any>(`${environment.primaryApiUrl}/recipes`, {
      params: {
        page,
        per_limit: take,
        filter: JSON.stringify(filter),
      },
    });
  }

  toggleRecipeToFavorite(recipeId: any): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/patient/diary/${recipeId}/favorite`,
      {},
    );
  }

  removeRecipe(recipe: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.primaryApiUrl}/patient/diary/${recipe.id}`,
    );
  }

  toggleRationToLocked(recipesId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/patient/diary/${recipesId}/locked`,
      {},
    );
  }
}

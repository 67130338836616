import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class DiaryService {
  constructor(private http: HttpClient) {}

  loadProducts(
    rationId: number,
    productFilter: any,
    take = 50,
  ): Observable<any> {
    const { page, ...filter } = productFilter;

    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/product`,
      {
        params: {
          page,
          per_limit: take,
          filter: JSON.stringify(filter),
          general: 0,
        },
      },
    );
  }

  addFoodToEatingRation(
    selectedFoodCard: any,
    rationId: number,
    weekId: number,
    dayId: number,
    eatingId: number,
  ): Observable<any> {
    const payload = {
      ration_eating_id: +eatingId,
      product_id: selectedFoodCard.product_id,
      type: selectedFoodCard.type,
      mass: 100,
    };
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/food`,
      payload,
    );
  }

  addEatingRation(
    rationId: number,
    weekId: number,
    dayId: number,
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/eating`,
      {},
    );
  }

  updateEatingRation(
    selectedEatingCard: any,
    rationId: number,
    weekId: number,
    dayId: number,
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/eating`,
      selectedEatingCard,
    );
  }

  removeEatingRation(
    selectedEatingCard: any,
    rationId: number,
    weekId: number,
    dayId: number,
  ): Observable<any> {
    return this.http.delete<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/eating/${selectedEatingCard.id}`,
    );
  }

  updateFoodRation(
    selectedFoodCard: any,
    rationId: number,
    weekId: number,
    dayId: number,
  ): Observable<any> {
    const payload = {
      id: selectedFoodCard.id,
      product_id: selectedFoodCard.product_id,
      type: selectedFoodCard.type,
      mass: selectedFoodCard.mass,
    };
    return this.http.put<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/food`,
      payload,
    );
  }

  removeFoodRation(
    selectedFoodCard: any,
    rationId: number,
    weekId: number,
    dayId: number,
  ): Observable<any> {
    return this.http.delete<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/food/${selectedFoodCard.id}`,
    );
  }

  replaceFoodRation(
    selectedReplaceableFoodCard: any,
    rationId: number,
    weekId: number,
    dayId: number,
    eatingFoodId: any,
  ): Observable<any> {
    const payload = {
      id: +eatingFoodId,
      product_id: selectedReplaceableFoodCard.product_id,
      type: selectedReplaceableFoodCard.type,
      mass: selectedReplaceableFoodCard.mass,
    };
    return this.http.put<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}/food`,
      payload,
    );
  }

  loadRationMonth(rationId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month`,
    );
  }

  loadRationWeeks(rationId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week`,
    );
  }

  loadRationWeek(rationId: number, weekId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}`,
    );
  }

  loadRationDays(rationId: number, weekId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day`,
    );
  }

  loadRationDay(
    rationId: number,
    weekId: number,
    dayId: number,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${dayId}`,
    );
  }

  addDayRation(
    rationId: number,
    weekId: number,
    selecteddayId: number,
  ): Observable<any> {
    // const payload = { select_day: selectedDayId };
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day`,
      {
        ration_id: rationId,
      },
    );
  }

  removeDayRation(
    rationId: number,
    weekId: number,
    selectedDayId: number,
    removingDayId: number,
  ): Observable<any> {
    const payload = { select_day: +selectedDayId || null };
    return this.http.request(
      "delete",
      `${environment.primaryApiUrl}/patient/diary/${rationId}/month/week/${weekId}/day/${removingDayId}`,
      { body: payload },
    );
  }

  loadWeeksForCopyEatingRation(rationId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/copy/eating`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      },
    );
  }

  loadFoodRecommendations(
    rationId: number,
    eatingFoodId: any,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/recommendation/${eatingFoodId}`,
    );
  }
}

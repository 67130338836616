import { DiarySettingsEffects } from "./diary-settings.effects";
import { DietarySupplementCategoriesEffects } from "./dietary-supplement-categories.effects";
import { DietarySupplementEffects } from "./dietary-supplement.effects";
import { PatientEffects } from "./patient.effects";
import { ProductEffects } from "./product.effects";
import { QuestionsEffects } from "./questions.effects";
import { RecipeEffects } from "./recipe.effects";

export const effects: any[] = [
  PatientEffects,
  QuestionsEffects,
  ProductEffects,
  DiarySettingsEffects,
  DietarySupplementCategoriesEffects,
  DietarySupplementEffects,
  RecipeEffects,
];

export * from "./diary-settings.effects";
export * from "./dietary-supplement-categories.effects";
export * from "./dietary-supplement.effects";
export * from "./patient.effects";
export * from "./product.effects";
export * from "./questions.effects";
export * from "./recipe.effects";

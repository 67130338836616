import { FilterParams } from "@app/shared/models/filter-params.model";
import { Product } from "@app/shared/models/product.model";
import { EatingFood } from "@app/shared/models/ration-details/eating-food.model";
import { createAction, props } from "@ngrx/store";

export const loadProducts = createAction(
  "[Food Diary Products / API] Load Products",
);
export const loadProductsSuccess = createAction(
  "[Food Diary Products / API] Load Products Success",
  props<{ products: Product[]; isAppend: boolean }>(),
);
export const loadProductsFailure = createAction(
  "[Food Diary Products / API] Load Products Failure",
  props<{ error: any }>(),
);

export const firstLoadProducts = createAction(
  "[Food Diary Products] First Load Products",
);

export const setProductFilter = createAction(
  "[Food Diary Products / API] Set Product Filter",
  props<{ productFilter: FilterParams }>(),
);

export const loadProductsNextPage = createAction(
  "[Food Diary Products / API] Load Products Next Page",
);

export const loadProductRecommendations = createAction(
  "[Food Diary Products / API] Load Product Recommendations",
);
export const loadProductRecommendationsSuccess = createAction(
  "[Food Diary Products / API] Load Product Recommendations Success",
  props<{ products: any }>(),
);
export const loadProductRecommendationsFailure = createAction(
  "[Food Diary Products / API] Load Product Recommendations Failure",
  props<{ error: any }>(),
);

export const setActiveRecommendedProduct = createAction(
  "[Food Diary Products] Set Active Recommended Product",
  props<{ selectedRecommendedProductId: number }>(),
);

export const addProductsDetailsToStorage = createAction(
  "[Food Diary Products] Add Products Details To Storage",
  props<{ products: EatingFood[] }>(),
);

export const clearProducts = createAction(
  "[Food Diary Products] Сlear Products",
);

export const resetProductsState = createAction(
  "[Food Diary Products] Reset Products State",
);

export const toggleProductToLocked = createAction(
  "[Food Diary Product / API] Toggle Product To Locked",
  props<{ product: Product }>(),
);
export const toggleProductToLockedSuccess = createAction(
  "[Food Diary Product / API] Toggle Product To Locked Success",
  props<{ product: any }>(),
);
export const toggleProductToLockedFailure = createAction(
  "[Food Diary Product / API] Toggle Product To Locked Failure",
  props<{ error: any }>(),
);

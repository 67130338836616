import { FilterParams } from "@app/shared/models/filter-params.model";
import { Product } from "@app/shared/models/product.model";
import { EatingFood } from "@app/shared/models/ration-details/eating-food.model";
import { createAction, props } from "@ngrx/store";

export const loadProfileDSProducts = createAction(
  "[Profile Dietary Supplement Products / API] Load Products",
);
export const loadProfileDSProductsSuccess = createAction(
  "[Profile Dietary Supplement Products / API] Load Products Success",
  props<{ products: Product[]; meta: any; isAppend: boolean }>(),
);
export const loadProfileDSProductsFailure = createAction(
  "[Profile Dietary Supplement Products / API] Load Products Failure",
  props<{ error: any }>(),
);

export const firstLoadProfileDSProducts = createAction(
  "[Profile Dietary Supplement Products / API] First Load Products",
);

export const setProfileDSProductFilter = createAction(
  "[Profile Dietary Supplement Products / API] Set Product Filter",
  props<{ productFilter: FilterParams }>(),
);

export const loadProfileDSProductsNextPage = createAction(
  "[Profile Dietary Supplement Products / API] Load Products Next Page",
);

export const loadProfileDSProductRecommendations = createAction(
  "[Profile Dietary Supplement Products / API] Load Product Recommendations",
);
export const loadProfileDSProductRecommendationsSuccess = createAction(
  "[Profile Dietary Supplement Products / API] Load Product Recommendations Success",
  props<{ products: any }>(),
);
export const loadProfileDSProductRecommendationsFailure = createAction(
  "[Profile Dietary Supplement Products / API] Load Product Recommendations Failure",
  props<{ error: any }>(),
);

export const setActiveRecommendedProfileDSProduct = createAction(
  "[Profile Dietary Supplement Products / API] Set Active Recommended Product",
  props<{ selectedRecommendedProductId: number }>(),
);

export const addProfileDSProductsDetailsToStorage = createAction(
  "[Profile Dietary Supplement Products / API] Add Products Details To Storage",
  props<{ products: EatingFood[] }>(),
);

export const clearProfileDSProducts = createAction(
  "[Profile Dietary Supplement Products / API] Сlear Products",
);

export const resetProfileDSProductsState = createAction(
  "[Profile Dietary Supplement Products / API] Reset Products State",
);

export const toggleProfileDSProductToLocked = createAction(
  "[Profile Dietary Supplement Products / API] Toggle Product To Locked",
  props<{ product: Product }>(),
);
export const toggleProfileDSProductToLockeProfileDSuccess = createAction(
  "[Profile Dietary Supplement Products / API] Toggle Product To Locked Success",
  props<{ product: any }>(),
);
export const toggleProfileDSProductToLockedFailure = createAction(
  "[Profile Dietary Supplement Products / API] Toggle Product To Locked Failure",
  props<{ error: any }>(),
);

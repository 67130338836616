import { FilterParams } from "@app/shared/models/filter-params.model";
import * as fromCategoryFilter from "@app/shared/stores/category-filter/store";
import * as fromNutrientFilter from "@app/shared/stores/nutrient-filter/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as ProductAction from "../actions/product.actions";

export const productsFeatureKey = "products";

export interface State extends EntityState<any> {
  selectedRecommendedProductId: number | null;
  isLoading: boolean;
  isLoaded: boolean;
  isFirstLoad: boolean;
  productFilter: FilterParams;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (product: any) => product.product_id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedRecommendedProductId: null,
  isLoading: false,
  isLoaded: false,
  isFirstLoad: false,
  productFilter: {
    page: 1,
    search: "",
    filters: [],
    order: "desc",
    dietRestriction: 0,
    categories: [],
  },
});

export const reducer = createReducer(
  initialState,

  on(ProductAction.loadProducts, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProductAction.loadProductsSuccess, (state, { products, isAppend }) => ({
    ...adapter.addMany(
      products,
      isAppend
        ? state
        : adapter.removeMany((product) => !product.locked, state),
    ),
    isLoading: false,
    isFirstLoad: false,
  })),
  on(ProductAction.loadProductsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(ProductAction.loadProductsNextPage, (state: any) => ({
    ...state,
    isLoading: true,
    productFilter: {
      ...state.productFilter,
      page: state.productFilter.page + 1,
    },
  })),

  on(ProductAction.firstLoadProducts, (state) => ({
    ...state,
    isFirstLoad: true,
  })),

  on(ProductAction.setProductFilter, (state, { productFilter }) => ({
    ...state,
    isLoading: true,
    productFilter: {
      ...state.productFilter,
      ...productFilter,
      page: 1,
    },
  })),

  on(
    fromCategoryFilter.applyCategoryFilterList,
    fromNutrientFilter.applyNutrientFilters,
    (state) => ({
      ...state,
      isLoading: true,
      productFilter: {
        ...state.productFilter,
        page: 1,
      },
    }),
  ),

  on(ProductAction.loadProductRecommendations, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    ProductAction.loadProductRecommendationsSuccess,
    (state, { products }) => ({
      ...adapter.setAll(products, state),
      isLoading: false,
      isFirstLoad: false,
    }),
  ),
  on(ProductAction.loadProductRecommendationsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(
    ProductAction.setActiveRecommendedProduct,
    (state, { selectedRecommendedProductId }) => ({
      ...state,
      selectedRecommendedProductId,
    }),
  ),

  on(ProductAction.clearProducts, (state) => ({
    ...adapter.removeAll({ ...state, ...initialState }),
  })),

  on(ProductAction.toggleProductToLocked, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProductAction.toggleProductToLockedSuccess, (state, { product }: any) => ({
    ...adapter.updateOne({ id: product.id, changes: product }, state),
    isLoading: false,
  })),
  on(ProductAction.toggleProductToLockedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectProductIds = selectIds;
export const selectProductEntities = selectEntities;
export const selectAllProducts = selectAll;
export const selectProductTotal = selectTotal;

export const getSelectedRecommendedProductId = (state: State) =>
  state.selectedRecommendedProductId;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectIsLoaded = (state: State) => state.isLoaded;
export const selectIsFirstLoad = (state: State) => state.isFirstLoad;
export const selectProductFilter = (state: State) => state.productFilter;

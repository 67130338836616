import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as RecipeActions from "../actions/recipe.actions";

export const recipesFeatureKey = "recipes";

export interface State extends EntityState<any> {
  selectedRecommendedRecipeId: number | null;
  isLoading: boolean;
  isFirstLoad: boolean;
  error: any;
  recipeFilter: {
    page: number;
    search: string;
    filters: any[];
    order: string;
    dietRestriction: any;
    categories: any[];
    cooking_type: any[];
  };
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (recipe: any) => recipe.product_id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedRecommendedRecipeId: null,
  isLoading: false,
  isFirstLoad: false,
  error: null,
  recipeFilter: {
    page: 1,
    search: "",
    filters: [],
    order: "desc",
    dietRestriction: 0,
    categories: [],
    cooking_type: [],
  },
});

export const reducer = createReducer(
  initialState,

  on(RecipeActions.resetFilters, (state) => ({
    ...state,
    recipeFilter: {
      page: 1,
      search: "",
      filters: [],
      order: "desc",
      dietRestriction: 0,
      categories: [],
      cooking_type: [],
    },
  })),
  on(RecipeActions.loadRecipes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RecipeActions.loadRecipesSuccess, (state, { recipes, isAppend }) => ({
    ...adapter.addMany(
      recipes,
      isAppend
        ? state
        : adapter.removeMany((recipe) => !recipe.isLocked, state),
    ),
    isLoading: false,
    isFirstLoad: false,
  })),
  on(RecipeActions.loadRecipesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(RecipeActions.loadRecipesNextPage, (state) => ({
    ...state,
    isLoading: true,
    recipeFilter: {
      ...state.recipeFilter,
      page: state.recipeFilter.page + 1,
    },
  })),

  on(RecipeActions.firstLoadRecipes, (state) => ({
    ...state,
    isFirstLoad: true,
  })),

  on(RecipeActions.setRecipeFilter, (state, { recipeFilter }) => ({
    ...state,
    isLoading: true,
    recipeFilter: {
      ...state.recipeFilter,
      ...recipeFilter,
      page: 1,
    },
  })),

  on(RecipeActions.toggleRecipeToFavorite, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RecipeActions.toggleRecipeToFavoriteSuccess, (state, { recipe }: any) => ({
    ...adapter.updateOne({ id: recipe.id, changes: recipe }, state),
    isLoading: false,
  })),
  on(RecipeActions.toggleRecipeToFavoriteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(RecipeActions.loadRecipeRecommendations, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RecipeActions.loadRecipeRecommendationsSuccess, (state, { recipes }) => ({
    ...adapter.setAll(recipes, state),
    isLoading: false,
    isFirstLoad: false,
  })),
  on(RecipeActions.loadRecipeRecommendationsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(
    RecipeActions.setActiveRecommendedRecipe,
    (state, { selectedRecommendedRecipeId }) => ({
      ...state,
      selectedRecommendedRecipeId,
    }),
  ),

  on(RecipeActions.clearRecipes, (state) => ({
    ...adapter.removeAll({ ...state, ...initialState }),
  })),

  on(RecipeActions.toggleRecipeToLocked, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RecipeActions.toggleRecipeToLockedSuccess, (state, { recipe }) => ({
    ...adapter.updateOne({ id: recipe.id, changes: recipe }, state),
    isLoading: false,
  })),
  on(RecipeActions.toggleRecipeToLockedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const getSelectedRecommendedRecipeId = (state: State) =>
  state.selectedRecommendedRecipeId;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectIsFirstLoad = (state: State) => state.isFirstLoad;
export const selectRecipeFilter = (state: State) => state.recipeFilter;
